import React, {useState} from "react";
import * as styles from "src/styles/Space-Planning-Software.module.css";
import { SEO, Picture } from "src/components/commonComponents";
import Modal from 'react-modal';
import Dot from "../../../static/staticPages/Dot.svg"
import Play1 from "../../../static/staticPages/Play1.svg"
import arrowRight from "../../../static/staticPages/arrowRight.svg"
import featureLogo from "../../../static/staticPages/featureLogo.svg"
import chartSimple from "../../../static/staticPages/chart-simple-solid.svg"
import chartLineDown from "../../../static/staticPages/chart-line-down-solid.svg"
import chartScatterBubble from "../../../static/staticPages/chart-scatter-bubble-solid.svg"
import featureProduct1 from "../../../static/staticPages/footer-ems-logo.png"
import featureProduct2 from "../../../static/staticPages/footer-famis-logo.png"
import featureProduct3 from "../../../static/staticPages/footer-observe-logo.png"
import minnesotaStateLogo from "../../../static/staticPages/Minnesota-State-logo.png"
import minnesotaBlack from "../../../static/staticPages/minnesota-black.png";
import logo1 from "../../../static/staticPages/Duke_logo.png"
import logo2 from "../../../static/staticPages/Minnesota_Twins_logo.png"
import logo3 from "../../../static/staticPages/co-op_logo.png"
import logo4 from "../../../static/staticPages/babsoncollege-logo.png"
import logo5 from "../../../static/staticPages/Anoka-County-logo.png"
import arrowRightBlue from "../../../static/staticPages/arrowRightBlue.svg"
import spaceHero2 from "../../../static/staticPages/space-hero2.png"
import spaceHero from "../../../static/staticPages/space-hero.jpg"
import emsLogo from "../../../static/staticPages/ems-logo.svg"
import lucernexLogo from "../../../static/staticPages/lucernex-logo.svg"
import redeyeLogo from "../../../static/staticPages/Logo-Redeye-color.png"
// import mcLogo from "../../../static/staticPages/mc-logo-new.png"
// import mcLogo from "src/images/new-version-homepage/Accruent-Maintenance-Connection-Color.png";
import mcLogo from "../../../static/staticPages/mc-logo.png"
import resource1 from "../../../static/staticPages/resource1_sp.jpeg"
import resource2 from "../../../static/staticPages/resource2_sp.jpeg"
import resource3 from "../../../static/staticPages/resource3_sp.jpeg"
import resource4 from "../../../static/staticPages/resource4_sp.jpeg"
import resource5 from "../../../static/staticPages/resource5_sp.jpeg"
import chartPieIcon from "../../../static/staticPages/chart-pie-solid.svg"
import calendarDayIcon from "../../../static/staticPages/calendar-day-solid.svg"
import calendarUsersIcon from "../../../static/staticPages/calendar-users-solid.svg"
import faceSmileIcon from "../../../static/staticPages/face-smile-solid.svg"
import chartLineUpIcon from "../../../static/staticPages/chart-line-up-solid.svg"
import leafIcon from "../../../static/staticPages/leaf-solid.svg"
import twoCol1 from "../../../static/staticPages/twoCol1.png"
import twoCol2 from "../../../static/staticPages/twoCol2.png"
import arrowDownBlue from "../../../static/staticPages/arrowDownBlue.svg"
import arrowUpBlue from "../../../static/staticPages/arrowUpBlue.svg"
import buildingssolidicon from "../../../static/staticPages/buildings-solid.svg";
import graduationcapsolidicon from "../../../static/staticPages/graduation-cap-solid.svg";
import { useHeroImages } from "src/hooks/useHeroImages";
import { getPageDataJsonPath } from "src/utils";
import { useFormImages } from "src/hooks/useFormImages";
import { ContentMarketoForm } from "src/components/commonComponents/forms/ContentMarketoForm";

const data = {
  heroBanner:{
    title: 'Space Planning and Management Software',
    description:'Unlock additional square footage, manage space allocations, optimize utilization, and lower your total cost of ownership',
    link1: {
      url: '/request-demo',
      text: 'Request a Demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: spaceHero,
      altText: 'Two students in a well-organized university space, highlighting space planning and management software'
    }
  },
  features: {
    title: 'The Power of Dedicated Space Planning Software',
    description: 'Understand your space utilization, identify capacity bottlenecks, and optimize space usage to streamline operations, balance budgets, and create exceptional experiences.',
    items: [
      {
        logoUrl: chartLineDown,
        altText: "Chart line icon representing the benefit",
        title: "Optimize Utilization and Lower Space-Related Costs",
        description: "Gain complete control over your workspace and resource utilization. Manage floor plans, track square footage usage, improve space-related decision-making, and increase efficiency across your assets and buildings."
      },
      {
        logoUrl: chartSimple,
        altText: "Simple Icon representing the benefit",
        title: "Eliminate Space Management Friction",
        description: "Use analytics to see your real estate ROI and big-picture utilization metrics in real time. Harness this data to make cost-saving and productivity-enhancing space decisions."
      },
      {
        logoUrl: chartScatterBubble,
        altText: "Scatter chart icon representing the benefit",
        title: "Use Centralized Data to Improve Decision-Making",
        description: "Centralize facility info, eliminate duplicate data, and house all essential information — including work order data, schedules, floor maps, and financials — in one central location to free up employee time and make smarter space choices."
      }
    ],
    info:{
      title: "Software Built to Optimize Your Space Planning ",
      images:[
      {
        url: featureProduct1,
        altText:"EMS product logo from Accruent",
        link:'/products/ems'
      },
      {
        url: featureProduct2,
        altText:"FAMIS 360 logo from Accruent",
        link:'/products/famis-360'
      },
      {
        url: featureProduct3,
        altText:"Observe logo from Accruent",
        link:'/products/vx-suite/vx-observe'
      }
    ],
    link: {
      text: 'Request a demo',
      url:'/request-demo'
    }
    }
  },
  fullWidthBanner:{
    image: {
      url: minnesotaBlack,
      altText:"Minnesota Colleges and Universities logo"
    },
    title:"Facilities Data Analyst, Minnesota Colleges and Universities",
    description: "“We were able to replace that 83,000 sq ft building with a 23,000 square foot building. We estimate the cost savings at about $50,000,000 that we were able to save because we were able to validate the smaller building rather than the larger building [using EMS].”",
    name: "Stacy Brown",
    position: "Facilities Data Analyst",
    // companyName: " Minnesota Colleges and Universities"
  },
  logoSlider:{
    logoList:[
      {
        url:logo1,
        altText:'Duke University logo '
      },
      {
        url:logo2,
        altText:'Minnesota Twins logo'
      },
      {
        url:logo3,
        altText:'Co-Op logo'
      },
      {
        url:logo4,
        altText:'Babson Colleges logo'
      },
      {
        url:logo5,
        altText:'Anoka County logo'
      },
    ]
  },
  threeColTwoRowComp: {
    title:"Simplify Your Space Management Once and For All",
    description: "Say goodbye to overcomplicated space and classroom scheduling",
    items:[
      {
        logoUrl:  calendarDayIcon,
        altText: "Calendar days icon",
        title:"Streamline Classroom and Exam Scheduling",
        description: "Eliminate complexities and redundancies to experience a smooth and efficient classroom scheduling process."
      },
      {
        logoUrl:  calendarUsersIcon,
        altText: "Calendar users icon",
        title:"Improve Conference and Event Management",
        description: "Simplify event management processes with flexible, fully integrated event management software."
      },
      {
        logoUrl:  faceSmileIcon,
        altText: "Smiley face icon",
        title:"Enhance Employee Experience and Satisfaction",
        description: "Utilize space planning tools to design collaborative areas, quiet zones, and wellness spaces that contribute to a positive work environment."
      },
      {
        logoUrl:  leafIcon,
        altText: "Leaf icon",
        title:"Promote Sustainable Practices",
        description: "Use space planning software to identify underutilized areas, reduce energy consumption, and implement sustainability initiatives within your real estate portfolio."
      },
      {
        logoUrl:  chartLineUpIcon,
        altText: "Chart line up icon",
        title:"Facilitate Strategic Growth Planning",
        description: "Analyze current space usage to support strategic planning for expansions, consolidations, or modifications without the guesswork."
      },
      {
        logoUrl:  chartPieIcon,
        altText: "Pie Chart icon",
        title:"Optimize Space Utilization ",
        description: "Take control of your space utilization and real estate footprint with purpose-built analytics, forecasting, and move management"
      }
    ]
  },
  twoColComp:{
    title: "Stay Ahead with Powerful Space Planning Capabilities",
    description: "Check out how EMS' powerful features can help your team eliminate space and resource scheduling headaches",
    link: {
      url: '/industries',
      text: "Explore industries using Accruent software"
    },
    items:[
      {
        logo: {
          url: buildingssolidicon,
          alt:'Building icon'
        },
        title: "Corporate Real Estate",
        description: "Accruent's EMS software optimizes space planning by providing comprehensive insights into corporate real estate, driving efficiency and high ROI with integrated data analytics.",
        link: {
          url: '/industries/corporate',
          text:'Explore corporate real estate'
        }
      },
      {
        logo: {
          url: graduationcapsolidicon,
          alt:'Graduation cap icon'
        },
        title: "Education",
        description: "Accruent's software, trusted by over 40% of top North American universities, delivers tailored physical resource management for educational institutions, ensuring optimal maintenance and space scheduling to align campus facilities with their academic mission.",
        link: {
          url: '/industries/education',
          text:'Explore education facilities management'
        }
      }
    ]
  },
  heroBanner1:{
    title: "Interested in optimizing your space? Take a deeper look at Accruent's space planning solution.",
    description:'Access a live one-on-one demo with an Accruent representative, today.',
    link1: {
      url: '/request-demo',
      text: 'Request a Demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: spaceHero2,
      alt: 'Space Planning'
    }
  },
  services: {
    title: "Products designed to integrate your world",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    items:[
      {
        logo:{
          url: emsLogo,
          alt: "Accruent EMS Logo"
        },
        title: "Desk, Room and Event Scheduling Made Easy",
        description:"Workplace management software simplifies desk, meeting and resource scheduling for your office or campus.",
        link:{
          url: '/products/ems',
          text: "Explore EMS"
        }
      },
      {
        logo:{
          url: lucernexLogo,
          alt: "Accruent Lucernex Logo"
        },
        title: "Real Estate Management Software",
        description:"Market-leading real estate software solutions manage lease, site planning and construction complexities.",
        link:{
          url: '/products/lucernex',
          text: "Explore Lucernex"
        }
      },
      {
        logo:{
          url: redeyeLogo,
          alt: "Accruent Redeye Logo"
        },
        title: "Engineering Document Management Made Easy",
        description:"Engineering document management system streamlines compliance and maximizes compay-wide collaboration.",
        link:{
          url: '/products/redeye',
          text: "Explore RedEye"
        }
      },
      {
        logo:{
          url: mcLogo,
          alt: "Accruent Maintenance Connection Logo"
        },
        title: "Multi-site, multi-industry CMMS software",
        description:"CMMS software reduces downtime, predicts maintenance needs and managers asset and equipment inventory.",
        link:{
          url: '/products/maintenance-connection',
          text: "Explore Maintenance Connection"
        }
      }
    ]
  },
  twoColHeadingComp: {
    title:"Find the right Accruent software for your exact industry",
    items:[
      {
        image:{
          url: twoCol1,
          alt: 'Alt'
        },
        title: "AMIS 360 Space Planning ",
        description:"Use FAMIS CMMS and Space Planning software to gain more control and visibility into the allocation of space across your portfolio.",
        info:[
          {text: "Automate space assignments by organizational department or functional activity"},
          {text: "Uncover additional square footage or discover square footage underutilization"},
          {text: "Identify and resolve capacity bottlenecks"},
          {text: "Integrate your space and facilities data for smarter decision-making"}
        ],
        link:{
          url:'/',
          text:"Access 360 planning brochure"
        }
      },
      {
        image:{
          url: twoCol2,
          alt: 'Alt'
        },
        title: "EMS Space Planning",
        description:"EMS space management software provides key space usage insights that can help you cut costs, improve your bottom line, and maximize flexibility.",
        info:[
          {text: "Track critical metrics like space usage, cancellations, meeting types, service changes, and more"},
          {text: "Lower real estate costs with more than 120 pre-built, flexible utilization reports"},
          {text: "Integrate with building systems like maintenance and HVAC to automate processes and centralize insights"},
        ],
        link:{
          url:'/',
          text:"Explore space planning optimizations"
        }
      }
    ]
  },
  resources: {
    title: "Some resources you might find interesting",
    link:{
      url:'/resources',
      text:"Explore Accruent resources"
    },
    resourceList:{
      resource1: {
        image:{
          url: resource1,
          altText: "Optimizing Your Company's Space and Facility Management in 2024 and Beyond"
        },
        title:"Optimizing Your Company's Space and Facility Management in 2024 and Beyond",
        description:"Understand recent real estate & facility management trends and learn how to respond effectively as an organization.",
        link: '/resources/white-papers/optimizing-your-companys-space-and-facility-management-2024-and-beyond',
        button:{
          text: 'White Paper',
        }
      },
      resource2: {
        image:{
          url: resource2,
          altText: 'FAMIS 360 Space Planning'
        },
        title:"FAMIS 360 Space Planning",
        description:"Hear from Duke University's Ryan Cakerice about the game-changing switch from EMS Professional to the EMS Enterprise solution.",
        link: '/resources/brochures/famis-space-planning',
        button:{
          text: 'Brochure',
        }
      },
      list:[
        {
          image:{
            url: resource3,
            altText: 'How FAMIS 360 Space Planning Helps Facilitate a Safe Return to Campus'
          },
          title:"How FAMIS 360 Space Planning Helps Facilitate a Safe Return to Campus",
          link: '/resources/blog-posts/how-famis-360-space-planning-helps-facilitate-safe-return-campus',
          button:{
            text: 'Blog',
          }
        },
        {
          image:{
            url: resource4,
            altText: 'Direct Spaces: A Modern Mobile Experience'
          },
          title:"Direct Spaces: A Modern Mobile Experience",
          link: '/resources/brochures/direct-spaces-modern-mobile-experience',
          button:{
            text: 'Brochure',
          }
        },
        {
          image:{
            url: resource5,
            altText: 'Maximizing Efficiency: The Role of Data in Space Planning and Management'
          },
          title:"Maximizing Efficiency: The Role of Data in Space Planning and Management",
          link: '/resources/blog-posts/maximizing-efficiency-role-of-data-space-planning-and-management',
          button:{
            text: 'Blog',
          }
        },
        // {
        //   image:{
        //     url: resource4,
        //     altText: 'altText'
        //   },
        //   title:"Gain a Competitive Edge: Top Trends in 2024 for the Retail Real Estate Industry",
        //   link: '/',
        //   button:{
        //     text: 'Webinar',
        //   }
        // },
        // {
        //   image:{
        //     url: resource5,
        //     altText: 'altText'
        //   },
        //   title:"TMS: Healthcare CMMS & Asset Management",
        //   link: '/',
        //   button:{
        //     text: 'Brochure',
        //   }
        // }
      ]
    },
    walkthrough: {
      title:"Walkthrough demonstrations",
      items:[
        {
          logo:{
            url:Play1,
            altText: 'Alt text'
          },
          title:"EMS: Meeting & Room Scheduling",
          description: "Eliminate complexities and redundancies to experience a smooth and efficient meeting and room scheduling process",
          link: "https://accruent.wistia.com/medias/hgbgp4tj44",
          wistiaId: 'hgbgp4tj44'
        },
        {
          logo:{
            url:Play1,
            altText: 'Alt text'
          },
          title:"EMS: Custom Reports",
          description: "Choose from 100+ standard EMS reports or build and share customized reports using the query builder",
          link: "https://accruent.wistia.com/medias/94oms53nx8",
          wistiaId: '94oms53nx8'
        },
        {
          logo:{
            url:Play1,
            altText: 'Alt text'
          },
          title:"FAMIS 360: Multiple Reporting Options",
          description: "Get the insights you need with multiple FAMIS reporting options, including visual space management reports and overview dashboards.",
          link: "https://accruent.wistia.com/medias/cvn86zavdu",
          wistiaId: 'cvn86zavdu'
        }
      ]
    }
  },
  support:{
    logo:{
      url: featureProduct2,
      altText: 'Design Logo'
    },
    title: 'Frequently Asked Questions',
    list: [
      {
        title: 'What is Space Planning Software?',
        descList: [
          {
            text: '<span>Space Management Software is a specialized tool designed to optimize the use and allocation of physical spaces within buildings or campuses. This software is beneficial for large organizations, educational institutions, or property managers who need to manage multiple spaces efficiently.</span>'
          },
          {
            text: '<span>Accruent space management software reduces manual workload and enhances the overall experience for both managers and end-users of the spaces by automating many aspects of space management.</span>'
          }
        ]
      },
      {
        title: 'What does Space Management System Encompass?',
        descList: [
          {
            text: '<span>A Space Management System helps to optimize the use and management of physical spaces, particularly in organizational or institutional settings. Key aspects often include:</span>'
          },
          {
            text: '<span>Space Planning and Allocation  </br> Inventory and Asset Management </br> Utilization Analysis  </br> Facility Management Integration  </br> Compliance and Standards Adherence </br> Reporting and Visualization Tools  </br> Collaboration and Communication Tools  </br> Scalability and Flexibility  </br> Integration with Other Systems  </br> User Accessibility and Interface </span>'
          }
        ]
      },
      {
        title: 'What are the Benefits of Dedicated Space Management Software?',
        descList: [
          {
            text: '<span>The benefits of a dedicated space management software include:</span>'
          },
          {
            text: '<span>Efficient Space Utilization   </br> Improved Planning and Forecasting  </br> Enhanced Collaboration and Communication   </br> Cost Savings   </br> Regulatory Compliance </span>'
          }
        ]
      },
      {
        title: 'How Does Space Allocation Software Enhance Efficiency?',
        descList: [
          {
            text: '<span>A Space allocation software can significantly enhance efficiency in various ways:</span>'
          },
          {
            text: '<span>Space Utilization </br> Data-Driven Decisions   </br> Flexibility and Scalability    </br> Cost Savings   </br> Enhanced Employee Experience  </br> Real-Time Updates and Reporting  </br> Environmental Benefits   </span>'
          }
        ]
      },

    ],
    contact: {
      title: 'Don’t see your question? Let us help you',
      link: {
        url:'/contactus',
        text:'Contact support'
      }
    }
  },
}

const HeroBanner = (heroImages) => {
  return <>
    <div className={styles.heroContainer}>
      <div className={styles.leftWrapper}>
        <h1 className={styles.heroTitle}>
          {data?.heroBanner?.title}
        </h1>
        <p className={styles.heroDesc}>{data?.heroBanner?.description}</p>
        <div className={styles.heroBtnWrapper}>
          <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
            <span className={styles.heroBtn1Text}>{data?.heroBanner?.link1?.text}</span>
            <div className={styles.heroBtn1}>
              <img className={styles.heroBtn1Img} src={Dot}></img>
            </div>
          </a>
          <a href={data?.heroBanner?.link2?.url} className={styles.heroBtn2Wrapper}>
            <span className={styles.heroBtn2Text}>{data?.heroBanner?.link2?.text}</span>
            <img src={arrowRight}></img>
          </a>
        </div>
      </div>
      <div>
        <Picture
            className={styles.heroImage}
            image={heroImages.heroImages['staticPages/space-hero.jpg']}
            rel="preload"
            alt={data?.heroBanner?.image?.altText}
          />
      </div>
    </div>
  </>
}

const Question = ({item}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.supportListWrapper}>
      <div onClick={()=>setOpen(!open)} className={styles.supportListTitleWrapper}>
        <img src={open ? arrowUpBlue : arrowDownBlue} alt='arrow'></img>
        <h3 className={styles.supportListTitle}>{item?.title}</h3>
      </div>
      {open && <div className={styles.supportListDescWrapper}>
          {
            item?.descList?.map((i, index)=>
              <p key={index} className={styles.supportListDesc} dangerouslySetInnerHTML={{ __html: i?.text }}>
              </p>
            )
          }
        </div>
      }
    </div>
  )
}

const Support = () => {
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportHeadWrapper}>
        {/* <img className={styles.supportLogo} src={data?.support?.logo?.url} alt={data?.support?.logo?.altText}></img> */}
        <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
        <div className={styles.supportQListWrapper}>
        {
          data?.support?.list?.map((item, index)=>
            <Question key={index} item={item}/>
          )
        }
      </div>
      </div>
      <div className={styles.supportContactWrapper}>
        <p className={styles.supportContactText}>{data?.support?.contact?.title}</p>
        <a className={styles.supportContactLink} href={data?.support?.contact?.link?.url}>
          <span className={styles.supportContactLinkText}>{data?.support?.contact?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureGridCompWrapper}>
        <div className={styles.featureHeadingWrapper}>
          <h2 className={styles.featureTitle}>{data?.features?.title}</h2>
          <p className={styles.featureDesc}>{data?.features?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.features?.items?.map((item,index)=>
              <div key={index} className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText} loading="lazy"></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
            )
            
          }
        </div>       
      </div>
      <div className={styles.featureProductsWrapper}>
        <p className={styles.featureProductsText}>{data?.features?.info?.title}</p>
        <div className={styles.featureProductsLogoList}>
          {
            data?.features?.info?.images?.map((image,index)=>
              <a href={image?.link} key={index} className={styles.featureProductsLogoWrapper}>
                <img src={image?.url} alt={image?.altText} className={styles.featureProductsLogo} loading="lazy"></img>
              </a>
            )
          }
        </div>
        <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.features?.info?.link?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
        </a>
      </div>
    </div>
  )
}

const FullWidthBanner = () => {
  return (
    <div className={styles.fullWidthBannerContainer}>
      <div className={styles.fullWidthBannerWrapper}>
        <div className={styles.fullWidthBannerLogoWrapper}>
          <div className={styles.fullWidthBannerLogo}>
            <img className={styles.fullWidthBannerImage} src={data?.fullWidthBanner?.image?.url} 
              alt={data?.fullWidthBanner?.image?.altText} loading="lazy"></img>
          </div>
        </div>
        <div className={styles.fullWidthBannerTextWrapper}>
          <p className={styles.fullWidthBannerTitle}>
            {data?.fullWidthBanner?.title}
          </p>
          <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.description}</p>
          <div>
            <p className={styles.fullWidthBannerName}>— {data?.fullWidthBanner?.name}</p>
            <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position} { data?.fullWidthBanner?.companyName ? ", " + data?.fullWidthBanner?.companyName  : ""}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const LogoSlider = () => {
  return (
    <div className={styles.logoSliderContainer}>
      <div className={styles.logoStrip}>
      { data?.logoSlider?.logoList?.map((logo, index)=>
        <img key={index} className={styles.logoSliderImage} src={logo?.url} alt={logo?.altText} loading="lazy"></img>
      )
      }
      </div>
    </div>
  )
}

const ThreeColComp = () => {
  return (
    <div className={styles.threeColContainer}>
      <div className={styles.threeColHeadWrapper}>
        <h2 className={styles.threeColTitle}>{data?.threeColTwoRowComp?.title}</h2>
        <p className={styles.threeColDesc}>{data?.threeColTwoRowComp?.description}</p>
      </div>
      <div className={styles.threeColGridWrapper}>
        {
          data?.threeColTwoRowComp?.items?.map((item, index)=>
            <div key={index} className={styles.threeColGridBox}>
              <div className={styles.threeColGrid}>
                <div className={styles.threeColLogoWrapper}>
                  <img src={item?.logoUrl} alt={item?.altText} loading="lazy"></img>
                </div>
                <h3 className={styles.threeColGridTitle}>{item?.title}</h3>
                <p className={styles.threeColGridDesc}>{item?.description}</p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

const TwoColComp = () => {
  return (
    <div className={styles.twoColCompContainer}>
      <div className={styles.twoColCompLeftWrapper}>
        <div className={styles.twoColCompTextWrapper}>
          <h2 className={styles.twoColCompTitle}>
              {data?.twoColComp?.title}
          </h2>
          <p className={styles.twoColCompDesc}>
              {data?.twoColComp?.description}
          </p>
        </div>
        <a href={data?.twoColComp?.link?.url} className={styles.twoColCompLinkWrapper}>
          <span className={styles.twoColCompLinkText}>{data?.twoColComp?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      </div>
      <div className={styles.twoColCompRightWrapper}>
        {
          data?.twoColComp?.items?.map((item, index)=>
            <div key={index} className={styles.twoColCompCardWrapper}>
              <div className={styles.twoColCompCardTextWrapper}>
                <div className={styles.twoColCompCardLogoWrapper}>
                  <img src={item?.logo?.url} alt={item?.logo?.alt} loading="lazy"></img>
                </div>
                <h3 className={styles.twoColCompCardTitle}>{item?.title}</h3>
                <p className={styles.twoColCompCardDesc}>{item?.description}</p>
              </div>
              <a href={item?.link?.url} className={styles.twoColCompCardLink}>
                <span className={styles.twoColCompCardLinkText}>{item?.link?.text}</span>
                <img src={arrowRightBlue} alt='Right arrow'></img>
              </a>
            </div>
          )
        }
      </div>
    </div>
  )
}

const HeroBanner1 = () => {
  return <>
     <div className={styles.heroContainer1}>
        <div className={styles.leftWrapper}>
          <h2 className={styles.hero1Title}>
            {data?.heroBanner1?.title}
          </h2>
          <p className={styles.hero1Desc}>{data?.heroBanner1?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner1?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src={Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner1?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Text}>{data?.heroBanner1?.link2?.text}</span>
              <img src={arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
          <img className={styles.heroImage} src={data?.heroBanner1?.image?.url} alt={data?.heroBanner1?.image?.alt} loading="lazy"></img>
        </div>
     </div>
  </>
}

const Services = () => {
  return (
    <div className={styles.serviceContainer}>
      <div className={styles.serviceHeadWrapper}>
        <h2 className={styles.serviceTitle}>{data?.services?.title}</h2>
        <p className={styles.serviceDesc}>{data?.services?.description}</p>
      </div>
      <div className={styles.serviceCardContainer}>
        {
          data?.services?.items?.map((item,index)=>
            <div key={index} className={styles.serviceCardWrapper}>
            <div className={styles.serviceCardTextWrapper}>
              <div className={styles.serviceCardLogoWrapper}>
                <img className={styles.serviceCardLogo} src={item?.logo?.url} alt={item?.logo?.alt} loading="lazy"></img>
              </div>
              <div className={styles.serviceCardText}>
                <h3 className={styles.serviceCardTitle}>{item?.title}</h3>
                <p className={styles.serviceCardDesc}>{item?.description}</p>
              </div>
            </div>
            <a href={item?.link?.url} className={styles.serviceCardLinkWrapper}>
              <span className={styles.serviceCardLinkText}>{item?.link?.text}</span>
              <img src={arrowRightBlue} alt='Right arrow'></img>
            </a>
          </div>
          )
        }
      </div>
    </div>
  )
}

const TwoColHeading = () => {
  return (
    <div className={styles.twoColContainer}>
      <h5 className={styles.twoColTitle}>
        {data?.twoColHeadingComp?.title}
      </h5>
      <div className={styles.twoColGridWrapper}>
       { data?.twoColHeadingComp?.items?.map((item, index)=>
          <div key={index} className={styles.twoColGriditemBox}>
            <div>
              <img className={styles.twocColGridImage} src={item?.image?.url} alt={item?.image?.alt} loading="lazy"></img>
              <div className={styles.twocColGridContentWrapper}>
                <div className={styles.twocColGridContent}>
                  <h5 className={styles.twocColGridTitle}>{item?.title}</h5>
                  <p className={styles.twocColGridDesc}>{item?.description}</p>
                    <ul className={styles.twocColGridDescList}>
                      { item?.info?.map((t, index)=>
                        <li key={index}>{t?.text}</li> 
                    )}
                    </ul>
                </div>
              </div>
            </div>
            <div className={styles.twoColGridLink}>
              <a href={item?.link?.url} className={styles.heroBtn2Wrapper}>
                    <span className={styles.heroBtn2Text}>{item?.link?.text}</span>
                    <img src={arrowRight}></img>
              </a>
            </div>
          </div>
      )
        }
      </div>
    </div>
  )
}

const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText} loading="lazy"/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index) =>
                <a key={index} href={item?.link} className={styles.resourceMultiCard}>
                  <div>
                    <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                  <div className={styles.resourceMultiCardTextWrapper}>
                    <p className={styles.resourceMultiCardText}>{item?.title}</p>
                    <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                      <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                    </div>
                  </div>
                </a>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.walkthroughWrapper}>
        <h2 className={styles.walkthroughTitle}>{data?.resources?.walkthrough?.title}</h2>
        <div className={styles.walkthroughFlexWrapper}>
          <VideoGrid />
        </div>
      </div>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const VideoModal = ({ isOpen, onRequestClose, wistiaId }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <div>
      <button onClick={onRequestClose} style={closeButtonStyles}>x</button>
      <div
        className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}
        style={{height:'360px', width:'640px'}}
      >
        &nbsp;
      </div>
    </div>
  </Modal>
);

const VideoGrid = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (wistiaId) => {
    setCurrentVideo(wistiaId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      {data?.resources?.walkthrough?.items?.map((video, index) =>
        <div className={styles.walkthroughCardWrapper} onClick={() => openModal(video.wistiaId)}>
          <img src={video?.logo?.url} alt={video?.logo?.altText}></img>
          <div className={styles.walkthroughCardText}>
            <p className={styles.walkthroughCardTitle}>{video?.title}</p>
            <p className={styles.walkthroughCardDesc}>{video?.description}</p>
          </div>
        </div>
      )
      }
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        wistiaId={currentVideo}
      />
    </>
  );
};

export default function SpacePlanningSoftware() {
  const heroImages = useHeroImages();
  const pageDataPath = getPageDataJsonPath('/solutions/space-planning-software')
  const formImages = useFormImages()
  const contentEntity = {
    "uuid": "abfd837b-6954-4cb8-a9c1-9c100cccda3b",
    "nid": 4046,
    "fieldEmbedCode": "<script src=\"//app-abc.marketo.com/js/forms2/js/forms2.min.js\"></script>\r\n<form id=\"mktoForm_1693\"></form>\r\n<script>MktoForms2.loadForm(\"//app-abc.marketo.com\", \"167-BOY-362\", 1693, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    "title": "Request A Demo ",
    "fieldFormHeader": "Request A Demo",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldFormText": null,
    "fieldFormImage": {
        "alt": "Form Image"
    },
    "relationships": {
        "fieldFormImage": {
        },
        "fieldFormImageWithCover": formImages['staticPages/form-face.png'],
        "fieldMediaImage": {
            "fieldImage": {
                "alt": "bg"
            },
            "relationships": {
                "field_image": formImages['staticPages/form-bg.jpg'],
            }
        }
    },
    "type": {
        "id": "marketo_forms"
    },
    "fieldVersions": "advanced",
    "fieldCtaUrl": null
  }
    return (
      <>
        <SEO
          meta={
            [
              { 'name': 'title', 'content': 'Best Space Planning and Management Software | Accruent' },
              { 'name': 'description', 'content': "Accruent's EMS space planning software automates, manages, and optimizes space utilization for greater efficiency across your assets and buildings." }
            ]
          }
          heroImage={heroImages['staticPages/space-hero.jpg']}
          preloads= {[pageDataPath]}
          lang="en"
          pageUrl="/solutions/space-planning-software"
          alternatives={[
            {
                "href": "/solutions/space-planning-software",
                "hreflang": "en"
            },
            {
                "href": "/uk/solutions/space-planning-software",
                "hreflang": "en-gb"
            },
            {
                "href": "/solutions/space-planning-software",
                "hreflang": "x-default"
            }
        ]}
        />
          <HeroBanner heroImages={heroImages}/>
          <Features/>
          <FullWidthBanner/>
          <LogoSlider/>
          <ThreeColComp/>
          <TwoColComp/>
          <HeroBanner1/>
          <Services/>
          {/* <TwoColHeading/> */}
          <Resource/>
          <div id="mktoForm">
            <ContentMarketoForm
              entity={contentEntity}
              locale="en"
              pageTitle="Space Planning: Best Space Planning and Management Software"
              formFullWidth={false}
            />
          </div>
          <Support/>
      </>
    );
  }
  